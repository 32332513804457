.dialog-layout {
  height: 100%;
  display: grid;
  grid-template-columns: auto 350px;
  overflow-y: hidden;

  .dialog-view {
    width: 100%;
    overflow-y: auto;
  }

  .dialog-sidebar {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: auto;
    width: 100%;
  }
}
