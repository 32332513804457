.exported-datacenter-view {
  padding: 10px;
  overflow-y: auto;
}

.generator-inputs {
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
