.notification {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-width: 600px;

  .message{
    font-size: 9px;
  }

  .bolded{
    font-weight: bold;
  }
}
