.canvas-container {
  border: 2px solid red;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding-top: 8px;
}

.scene {
  overflow: hidden;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: start;
  min-width: 360px !important;
  user-select: none;

  .scene-item-card {
    min-width: 370px !important;
    max-width: 800px !important;
    border-radius: 25px !important;
    border: 1px solid #dcd7da;
    overflow: hidden;
  }

  .scene-item-card:hover {
    border: 1px solid black;
  }

  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .checkbox {
    display: inline-block;
    height: 28px;
    width: 70px;
    background: #fff;
    border-radius: 25px;
    padding: 0px;
  }

  label {
    height: 28px;
  }

  .scene-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
    padding: 0px 20px;
  }

  .button {
    height: 28px;
    width: 70px;
  }

  .remove-button {
    width: 65px;
    height: 28px;
  }

  .scene-title {
    font-size: 12px;
    font-weight: 600;
    max-height: 28px;
    align-items: center;
    display: flex;
  }

  .card-content {
    padding-left: 0px !important;
    padding-right: 12px !important;
  }
}
