@import "./colors.scss";
@import "./theme-variables.scss";

.MuiDataGrid-columnHeaderTitle {
  font-weight: 900;
  color: black;
}

.MuiDialogTitle-root {
  font-weight: 900;
  color: black;
}

.MuiSelect-root,
.MuiFormControl-root,
.MuiTextField-root {
  border: 0px;
  width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDialogContent-root{
  padding: 10px 24px !important;
}

.MuiTypography-root-MuiDialogTitle-root{
  padding: 0px 24px !important;
}

.MuiDialogActions-root{
  padding: 5px 20px 10px 20px !important;
}

.MuiCardContent-root{
  padding: 10px 5px 15px 5px !important;
}

.MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.87) !important;
}