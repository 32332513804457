.generate-buildings {
  padding: 20px 30px 20px 30px;
  overflow-y: auto;
  max-height: calc(100vh - 160px);

  .section-title {
    font-weight: 800;
    font-size: 16px;
  }

  .button-container {
    display: flex;
    justify-content: end;
  }
}
