.generate-building-view {
  width: 100%;
  position: relative;

  .custom-icon {
    fill: #333333 !important;
  }

  .rotate-buttons {
    z-index: 1000;
    position: absolute;
    left: 10px;
    top: 15px;
    border-radius: 4px;
    width: auto;
    height: auto;
    padding: 5px;

    .rotate-button {
      align-items: center;
      display: flex;
    }
  }

  .map-detail-view {
    display: flex;

    .map {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .building-detail-view {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px 15px 20px 20px;
      width: 475px;
      height: calc(100vh - 130px);
    }
  }
}
