.add-button {
  display: flex;
  align-items: center;
  max-width: 200px;

  &:hover {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  .disabled {
    fill: #dddddd;
  }
  .enabled {
    fill: rgba(0, 52, 102, 1);
  }
}

