.options-view {
  height: 100%;
  overflow-y: hidden;

  .options-view-header {
    max-width: 300px;
    display: flex;
    align-items: end;
  }

  .hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .options-view-grid {
    max-height: calc(100vh - 225px);
    overflow-y: auto;
  }
}
