@import "../../../../styles/theme-variables.scss";

.input-pane {
  height: 100%;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: $input-pane-drawer-width;
  max-width: $input-pane-drawer-width;
  overflow-x: hidden;
  overflow-y: hidden;

  .input-pane-datacenter-content {
    height: calc(100vh - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .input-pane-title {
    margin: 20px 20px 10px 20px;
  }

  .input-pane-components-content {
    height: calc(100vh - 4rem);
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .input-pane-button {
    position: absolute;
    display: flex;
    left: $input-pane-drawer-width - 50px;
  }

  .basic-principles-header {
    height: auto;
    display: flex;
    overflow-x: hidden;
    margin-top: -10px;
    align-items: center;
    justify-content: start;
    min-width: $input-pane-drawer-width - 1px;
  }
}
