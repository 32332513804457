
.exported-buildings-view {
  margin-left: -10px;
  overflow-y: auto;  
}

.scene-details-view {
  .scene-sidebar-view {
    width: 37%;
  }
}