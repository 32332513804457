.planning-view {
  display: flex;
  width: 100vw;
  flex-direction: row;
  overflow-y: auto;
  height: 85vh;
  margin-top: 2px;

  .gantt-task-list {
    display: none;
  }

  .arrow {
    display: none;
  }
  .ag-row {
    transform: translateY(0px) !important;
    color: "red" !important;
  }
  .ag-row-position-absolute {
    position: unset !important;
  }

  .ag-center-cols-container {
    min-width: 100% !important;
  }

  .ag-root-wrapper {
    border-radius: 0px;
  }

  .ag-body-horizontal-scroll {
    position: fixed;
    width: 50vw;
    overflow: auto;
    padding-right: 15px;
  }

  .full-width-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f2f2f2;
    border-top: 1px solid #ccc;
  }

  .ag-header {
    font-size: 12px !important;
  }

  .ag-body {
    flex: none !important;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: auto !important;
  }

  .ag-body-horizontal-scroll-viewport {
    scrollbar-width: thin !important;
  }

  text {
    fill: black !important;
  }

  .weekend-overlay {
    fill: rgba(0, 0, 0, 0.05);
  }

  ._2k9Ys {
    position: fixed !important;
    bottom: 0px;
    margin: 0px;
    padding: 0px;
    height: 1.05rem;
    width: 51vw;
  }

  .css-1rtad1 {
    position: fixed !important;
    scrollbar-width: thin !important;
    overflow: auto !important;
  }

  .divider {
    width: 2px;
    margin: 2px;
    cursor: ew-resize;
    background-color: #ddd;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
  }

  .divider:hover {
    background-color: #bbb;
  }

  .divider::before {
    content: "\2194";
    font-size: 12px;
    color: #888;
    background-color: white;
    border: 1px solid #888;
    border-radius: 4px;
    padding: 1px 3px;
    user-select: none;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease, border-color 0.2s ease,
      color 0.2s ease;
  }

  .divider:hover::before {
    color: #666;
    border-color: #666;
  }

  .left-pane {
    height: 100%;
    background-color: #f0f0f0;
    flex-grow: 1;
    height: fit-content;
    max-width: 100vw;
  }

  .right-pane {
    background-color: #ffffff;
    width: 50%;
    flex-grow: 1;
    max-width: 100vw;
    border-left: 0.5px solid rgb(204, 204, 204);
    overflow: hidden;
    font-size: 0;
    margin: 0;
    padding: 0;
    height: fit-content;
  }
}
