@import "../../../styles/theme-variables.scss";
.component-details {
  padding-top: 5px;
  overflow-x: hidden;
  overflow-y: hidden;

  .component-header {
    height: auto;
    display: flex;
    overflow-x: hidden;
    margin-top: -5px;
    margin-left: -14px;
    margin-bottom: -12px;
    align-items: center;
    justify-content: start;
    min-width: $input-pane-drawer-width - 1px;
  }
}
