.legend {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);  
  width: auto;

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .legend-color {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }

  .legend-line {
    width: 18px;
    height: 0px;
    margin-right: 5px;
  }

  .legend-label {
    font-size: 14px;
  }

  .legend-cross {
    text-align: center;
    font-size: 16px;
    margin-top: -3px;
  }
}
