@import "../../../../styles/colors.scss";
@import "../../../../styles/theme-variables.scss";

.divider:hover {
  background-color: #bbb;
}

.divider::before {
  content: "\2194";
  font-size: 12px;
  color: $heijmans-blue;
  background-color: white;
  border: 1px solid $heijmans-blue;
  border-radius: 4px;
  padding: 1px 3px;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease, border-color 0.2s ease,
    color 0.2s ease;
}

.divider:hover::before {
  color: #666;
  border-color: #666;
}
