@import "../../../styles/theme-variables.scss";

.projects-view {
  position: relative;
  padding: 25px;
  height: 100vh;
  overflow-y: hidden;

  .projects-view-header {
    display: flex;
    padding-bottom: 10px;

    .projects-view-title {
      width: 100%;
    }
  }

  .loading-indicator-datacenter-projects {
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-grid {
    margin-top: -5px !important;
    padding-right: 10px;
    padding-bottom: 20px;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
}
