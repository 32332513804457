@import "../../../../styles/colors.scss";

.data-grid-container {
  width: 100%;

  .title {
    color: $heijmans-blue;
    font-size: 14px;
  }

  .header-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title-view {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .buttons-view {
      display: flex;
      gap: 10px;
    }
  }

  .grid-view {
    display: grid;
    padding-top: 10px;
    height: calc(100vh - 125px);
  }
}
