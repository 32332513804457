@import "../../styles/theme-variables.scss";

.label-with-infobutton {
  .icon {
    margin-top: -3px !important;
    font-size: 25px;
  }
  .label{
    font-size: 14px;
    color: #8A8989;
  }
}
