@import "../../../../styles/theme-variables.scss";

.datacenter-control {
  .whitespace-header {
    height: auto;
    display: flex;
    overflow-x: hidden;
    margin-top: -10px;
    align-items: center;
    justify-content: start;
    min-width: $input-pane-drawer-width - 1px;
  }

  .low-voltage-room-header {
    height: auto;
    display: flex;
    overflow-x: hidden;
    margin-top: -10px;
    align-items: center;
    justify-content: start;
    min-width: $input-pane-drawer-width - 1px;
  }
}
