.drag-drop {
  background: #fff;
  border-radius: 8px;
  
  &[data-editing="false"] {
    opacity: 0.7;
    pointer-events: none;
    
    .document-uploader {
      background-color: #f5f5f5;
      cursor: not-allowed;
      
      .upload-info {
        .upload-info-text p {
          color: #888;
        }
        
        .upload-icon {
          opacity: 0.5;
        }
      }
    }
  }
}

.document-uploader {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #999;
  }

  &.active {
    border-color: #6dc24b;
    border-style: solid;
  }

  .upload-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: opacity 0.2s ease;

    .upload-info-text {
      text-align: center;
    }

    .upload-icon {
      margin-left: 40px;
      transition: opacity 0.2s ease;
    }

    svg {
      font-size: 36px;
      margin-right: 1rem;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
        transition: color 0.2s ease;
      }

      p:first-child {
        font-weight: bold;
      }
    }
  }

  .file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 30vh;
    &__container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: white;

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;

      p {
        margin: 0;
        font-size: 14px;
        color: #333;
      }
    }

    .file-actions {
      cursor: pointer;

      svg {
        font-size: 18px;
        color: #888;
      }

      &:hover {
        svg {
          color: #d44;
        }
      }
    }
  }

  .browse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-color);
    &:hover {
      background-color: transparent;
    }
  }

  .success-file {
    display: flex;
    align-items: center;
    color: #6dc24b;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  input[type="file"] {
    display: none;
  }
}