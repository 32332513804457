@import "./colors.scss";

$spacing: 8px;
$media-max-width: 599.95px;
$menu-drawer-width: 350px;
$input-pane-drawer-width: 425px;
$transition-duration: 1000ms;
$border-radius: 30px;
$sidebar-width: 90px;
$font-size: 12px;
$data-grid-column-width: 200;

$themes: (
  light: (),
  dark: (),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
