@import "../../../styles/colors.scss";
@import "../../../styles/theme-variables.scss";

.nitrogen-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: margin-left 2s;

  .save-icon-disabled {
    fill: $disabledColor;
  }

  .nitrogen-input-pane-button {
    position: absolute;
    display: flex;
    top: 136px;
    left: -15px;
  }
}

.dashboard-view {
  overflow-y: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
}

.dashboard-view-closed {
  overflow-y: hidden;
  width: 100%;
  height: calc(100vh - 110px);
  margin-left: -$input-pane-drawer-width;
}

.input-pane-header {
  display: flex;
  padding-right: 10px;
}
