.pdf-container {
  position: relative;

  .pdf-container-document {
    width: 100%;
    overflow-y: auto;
  }

  .delete-button {
    position: absolute;
    right: 50%;
    left: 50%;
    bottom: 10px;
  }

  .pdf-container-document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.pdf-container-document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.pdf-container-document .react-pdf__message {
  padding: 20px;
  color: white;
}
