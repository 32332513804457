@import "../../../styles/colors.scss";

.generate-data-datacenter {
  .MuiStepIcon-text {
    fill: white !important;
  }

  .MuiStepIcon-root.Mui-completed,
  .MuiStepIcon-root.Mui-active {
    color: $heijmans-blue !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: $heijmans-blue !important;
    color: white !important;
  }

  .MuiStep-root {
    padding-left: 0px;
    padding-right: 10px;
  }

  .loading-indicator-generate-datacenters {
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .datacenter-generation-view {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .switch-view {
    justify-content: center;
  }

  .android-switch {
    margin-left: -10px;
  }

  .toggle-button-left {
    width: 60px;
    height: 30px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid $heijmans-blue;
    color: $heijmans-blue;
  }

  .toggle-button-middle {
    width: 60px;
    height: 30px;
    border: 2px solid $heijmans-blue;
    color: $heijmans-blue;
  }

  .toggle-button-right {
    width: 60px;
    height: 30px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 2px solid $heijmans-blue;
    color: $heijmans-blue;
  }

  .chip-container{
    display: flex;
    flex-wrap: wrap;
    max-height: 40px;
    overflow-y: auto;
  }
}
