@import "../../styles/theme-variables.scss";

.menuButton {
  margin-left: 12px;
  margin-right: 36px;
}

.hide {
  display: none;
}

.drawer {
  border-radius: 0;
  width: $input-pane-drawer-width;
  flex-shrink: 0;
  white-space: nowrap;
}

.drawerOpen {
  border-radius: 0;
  width: $input-pane-drawer-width;
  transition: width #{$transition-duration} ease;
}

.drawerClose {
  border-radius: 0;
  transition: width #{$transition-duration} ease;
  overflow-x: hidden;
  width: 0;

  @media (max-width: 959.95px) {
    width: $input-pane-drawer-width;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 64px;

  @media (max-width: $media-max-width) {
    display: none;
  }
}

.content {
  flex-grow: 1;
  padding: 24px;
}

.sidebar {
  width: 350px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .sidebar-header {
    height: 30px;
    margin-top: 20px;
    padding: 20px 0px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-title {
    width: 100%;
    justify-self: start;
  }

  .sidebar-line {
    width: 100%;
    margin-top: 20px;
  }

  .sidebar-menu-items {
    flex: 1;
    padding: 5px 10px 5px 5px;
  }
}

.nav-link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: unset;
}

.nav-link .svg {
  fill: $heijmans-blue;
  stroke: $heijmans-blue;
}

.nav-link:hover,
.nav-link.active {
  color: $navLinkTextColor;
  fill: $navLinkTextColor;
  background-color: $navLinkHoverImageColor;
}

.nav-link:hover .svg,
.nav-link.active .svg {
  fill: $navLinkTextColor;
  stroke: $navLinkTextColor;
}
