@import "../styles/colors.scss";

.parallel-coordinates-plot {
  width: 100%;
  height: 90%;
  cursor: crosshair;
}

.brush .selection {
  fill: $heijmans-blue !important;
  fill-opacity: 0.5;
  stroke: $heijmans-blue !important;
}