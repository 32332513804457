.scenario-details-view {
  padding-left: 20px;

  .scenario-phases-diagram {
    position: relative;
    width: 100%;
    height: 400px;

    .custom-legend {
      padding: 5px;
      margin: 0;
      border: 1px solid #d3d3d3 !important;
      border-radius: 10px !important;

      .legend-item {
        margin-bottom: 5px;
        margin-top: 5px;
        display: flex;
        align-items: center;

        .legend-color {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #d3d3d3;
          margin-right: 8px;
        }
      }
    }
  }

  .scenario-graphs {
    overflow-y: auto;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    padding-right: 20px;

    .scenario-graph {
      height: 49%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
