.cost-section {
  padding: 16px 0;

  .value-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }

  .total {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
  
  .unit {
    min-width: 20px;
    text-align: center;
  }

  .title {
    font-weight: 800;
    font-size: 16px;
  }

  .subtitle-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subtitle {
      display: inline-block;
    }


  }

  .cost-row {
    align-items: end;
  }
}
