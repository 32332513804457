.low-voltage-room-content {
  padding: 10px 20px 20px 20px;
}

.slider-with-marks {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.filter-divider {
  background-color: rgba(0, 0, 0, 0.42);
  margin: 7px 0px 7px 0px !important;
}
