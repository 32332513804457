.zoom-image {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;

  .svg-container {
    flex-grow: 1;
    transition: transform 0.3s;
    transform: scale(var(--zoom-level)) translate(var(--pan-offset-x), var(--pan-offset-y));
  }

  .svg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  }
}
