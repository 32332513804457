@import "../../styles/theme-variables.scss";

.app-bar {
  width: 100vw;
  max-height: 68px;
  background-color: white;

  .heijmans-logo {
    margin-left: 2rem;
    margin-right: 3rem;
    width: 9rem;
    cursor: pointer;
  }
}

.toolbar {
  padding: 8px; // theme.spacing(1)
  padding-right: 20px;
  border-bottom: 1px solid #eee6e6;
}

.secondToolbar {
  padding: 8px; // theme.spacing(1)
  padding-right: 20px;
  margin-left: 300px;
}

.grow {
  flex-grow: 1;
}

.headerMenuButton {
  color: $heijmans-blue;
}

.headerIcon {
  color: $heijmans-blue;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userName {
  margin-left: 10px;
}

.userIcon {
  font-size: 24px;
  color: $heijmans-blue;
}

.toolbar-nav-icon {
  color: $heijmans-blue;
}

.caretRightIcon {
  color: $heijmans-yellow;
}

.header-label {
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 500;
  color: #000000de;
}

.secondToolbarLabel {
  margin-left: 16px;
  margin-right: 16px;
  font-weight: 500;
  color: $heijmans-blue;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  line-height: normal;
}

.secondToolbarButton {
  background-color: $heijmans-yellow;
  border-radius: 10px;
  color: white;
  padding: 0px 30px 0px 30px;
}

.secondToolbarNavIcon {
  margin-left: 16px; // theme.spacing(2)
  margin-right: 16px; // theme.spacing(2)
  font-size: 200%;
}

.breadcrumb-view {
  display: flex;
  justify-content: center;
  align-items: center;
}
