@import '../../styles/theme-variables.scss';


.components-page {
    padding: 25px;
    height: 100vh;
    overflow-y: auto;

    .sub-titel{
        font-size: 10px;
        margin-bottom: 10px;
    }
}