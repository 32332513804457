.base-select-object-view-container {
  overflow-y: auto;
  height: calc(100vh - 125px);
  overflow-x: hidden;
  grid-template-rows: 50% 50%;
  display: grid;

  .graph {
    width: 100%;
    height: 80%;
    max-height: 325vh;
    justify-self: center;
  }

  .button {
    align-self: center;
    max-height: 35px;
    width: max-content;
  }

  .reset-sidebar {
    margin-top: -5px;
    display: flex;
    justify-content: end;
    padding: 0px 15px 5px 10px;
    overflow: auto;
  }

  .reset-sidebar h5 {
    color: #333;
    margin: 10px 10px;
    display: flex;
    align-items: center;
  }

  .graph-view {
    position: relative;
  }

  .scene-view {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    transition: margin-right 1s;
    display: flex;
    position: relative;
  }

  .filter-view-open {
    position: absolute;
    left: 0px;
    bottom: 5px;
    display: flex;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    padding-right: 445px;
  }

  .filter-view-closed {
    position: absolute;
    left: 0px;
    bottom: 5px;
    display: flex;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    padding-right: 75px;
  }

  .infinite-scroll-view-open {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px 5px 5px 0px;
    margin-right: 405px;
  }

  .infinite-scroll-view-closed {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px 40px 5px 0px;
    margin-right: 0px;
  }

  .disable-pointer-events {
    pointer-events: none !important;
  }

  .add-rule-button {
    margin-left: 10px;
  }

  .sidebar-view {
    display: flex;
    align-items: center;
    padding: 10px 15px 0px 0px;

    .sidebar-button {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: start;
      align-items: center;
      z-index: 1;
    }

    .sidebar-title {
      width: 100%;
      margin-left: 30px;
      justify-self: start;
    }

    .sidebar-add-to-set {
      align-items: end;
      justify-content: end;
      margin-right: 5px;
    }

    .sidebar-reset-button {
      align-items: end;
      justify-content: end;
    }

    .sidebar-selected {
      padding: 10px;
      overflow-y: auto;
    }
  }
}

.loading-indicator-base-select-object {
  height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-items: center;
}
