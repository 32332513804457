@import "../../../../styles/colors.scss";

.project-requirements {
  .bicycle-parking-header {
    display: flex;
    align-content: center;
  }

  .bicycle-column-title {
    font-size: 10px;
    color: $input-label-color;
  }
}
