.whitespace-control {
  margin-top: -5px;
  padding: 0px 20px 20px 20px;

  .slider-with-marks{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
