@import "./assets/css/fonts.css";
@import "./styles/theme-variables.scss";
@import "./styles/mui-overrides.scss";

:root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "Poppins";
}

input {
  font-family: "Poppins";
}

.link {
  &:not(:first-child) {
    padding-left: 15px;
  }
}

.titel {
  color: $heijmans-blue;
  font-size: 13px;
  font-weight: 600;
}

.titel-black {
  font-size: 13px;
  font-weight: 600;
}

.titel-no-bold {
  font-size: 14px;
}

.big-title {
  font-size: 15px;
  font-weight: 800;
}

.sub-titel {
  font-size: 10px;
  color: #000000de;
  text-overflow: ellipsis;
}

.empty-conf {
  padding: 10px;
  font-style: italic;
}

.selection {
  fill: grey !important;
  stroke: none !important;
}

.drawer {
  border-radius: 0;
  width: $input-pane-drawer-width;
  flex-shrink: 0;
  white-space: nowrap;
}

.drawerOpen {
  border-radius: 0;
  width: $input-pane-drawer-width;
  transition: width #{$transition-duration} ease;
}

.drawerClose {
  border-radius: 0;
  transition: width #{$transition-duration} ease;
  overflow-x: hidden;
  width: 0;

  @media (max-width: 959.95px) {
    width: $input-pane-drawer-width;
  }
}

.sidebar-persistent {
  margin-left: $menu-drawer-width;
  height: 100%;
}

.sidebar-temporary {
  margin-right: 0px;
  height: 100%;
}

.center-center {
  display: flex;
  align-items: center;
}

.full-height {
  height: 100%;
}

.mt {
  margin-top: 1rem;
}

.full-width {
  width: 100%;
}

object,
object * {
  pointer-events: all !important;
}

.single-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-next-each-other-center {
  display: flex;
  justify-content: start;
  align-items: center;
}

.truncated-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flex-grow {
  flex-grow: 1;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mr20 {
  margin-right: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb25 {
  margin-bottom: 25px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.borderless-input {
  padding-top: 5px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}

.flex {
  flex: 1;
}

.save-icon-disabled {
  fill: $disabledColor !important;
}
