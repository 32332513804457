.editable-tree-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  max-height: 26px;

  .editable-node {
    display: flex;
    align-items: center;
    justify-content: end;
  }
}


