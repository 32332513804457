.property-section {
    margin-bottom: 10px;

    .property-section-title {
      font-size: 15px;
      font-weight: 800;
    }

    .MuiInputAdornment-root {
      .MuiTypography-root {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    .label-with-infobutton {
      .icon {
        margin-top: 0px !important;
        font-size: 18px;
      }
      .label{
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87);
      }
    }    
  }