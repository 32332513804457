.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  visibility: hidden;
}

.mapboxgl-ctrl-logo {
  visibility: hidden;
}

.mapboxgl-canvas-container {
  width: 100% !important;
}

.mapboxgl-ctrl-bottom-right {
  bottom: -20px;
  left: 0px;
}

.map-view {
  position: relative;
  height: calc(100vh - 130px);
  width: 100%;

  .map-container {
    width: 100%;
    margin-top: 3px;
    height: 100%;
  }

  .floating-action-button-layout {
    position: absolute;
    top: 10px;
    right: 10px;

    .floating-action-button {
      height: 40px;
      width: 40px;
      background-color: white;
    }

    .popover-content {
      margin-top: -20px;
      padding: 50px;
    }
  }
}
