.container {
  overflow-y: hidden;
  height: calc(93vh);
  display: grid;
  grid-template-rows: 7% 93%;

  .components-header {
    padding: 15px;
    display: flex;
  }

  .viewDetails {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 250px;
  }

  .parentContainer {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .candidate-selections {
    padding-top: 0px;
    overflow-y: auto;
    padding-bottom: 50px;
    height: 100%;
  }

  .sceneContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0px 20px 0px 20px;
  }

  .button {
    align-self: center;
    max-height: 35px;
    width: max-content;
  }

  .reset-sidebar {
    margin-top: -5px;
    display: flex;
    justify-content: end;
    padding: 0px 15px 5px 10px;
    overflow: auto;
  }

  .reset-sidebar h5 {
    color: #333;
    margin: 10px 10px;
    display: flex;
    align-items: center;
  }

  .selection-cursor {
    cursor: crosshair;
  }

  .showZoomedInCanvas {
    margin: 50px;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
    height: 100%;
  }

  .graph-view {
    position: relative;
  }

  .scene-view {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    transition: margin-right 1s;
    display: flex;
    position: relative;
  }

  .filter-view-open {
    position: absolute;
    left: 0px;
    bottom: 5px;
    display: flex;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    padding-right: 445px;
  }

  .filter-view-closed {
    position: absolute;
    left: 0px;
    bottom: 5px;
    display: flex;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    padding-right: 75px;
  }

  .infinite-scroll-view {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .disable-pointer-events {
    pointer-events: none !important;
  }

  .components-view {
    height: auto;
    overflow-y: auto;

    .loading-indicator-container {
      height: calc(100vh - 110px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .max-width-img {
      max-width: 100%;
      height: auto;
    }
  }
}
