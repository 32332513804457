.filter-panel {
    height: calc(100vh - 105px);
    overflow-y: auto;
    padding: 20px;
  }
  
  .filter-header {
    padding-top: 20px;
    padding-left: 20px;
  }
  