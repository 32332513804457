.sidebar-details {
  padding: 10px 20px 10px 20px;
  height: 100%;
  overflow-y: auto;

  .sidebar-section {
    margin-bottom: 10px;

    .sidebar-section-title {
      font-size: 15px;
      font-weight: 800;
    }

    .MuiInputAdornment-root {
      .MuiTypography-root {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
