.showZoomedInCanvas {
  width: 100%;
}

.scene-details-view {
  height: calc(100vh - 210px);
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .toggle-button {
    position: absolute;
    top: 20;
    left: 20;
  }

  .tab-view {
    height: calc(100vh - 210px);
    max-height: calc(100vh - 210px);
    display: flex;
  }

  .tab-page {
    width: 100%;
    height: 100%;
  }

  .image-view {
    position: relative;
    height: 100%;
    width: 72%;
  }

  .scene-sidebar-view {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    overflow: auto;
    width: 28%;
  }
}

.loading-indicator-scene-details {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-zoomed-in-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title {
  color: #333;
  font-weight: bold;
}

.value {
  display: flex;
  align-items: center;
  color: #666;
  height: 30px;
  border: 1px solid #333;
  border-radius: 5px;
  min-width: 130px;
  margin-left: 5px;
  padding-left: 5px;
}
