.hsa-generator-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: margin-left 2s;

  .hsa-input-pane-button {
    position: absolute;
    display: flex;
    top: 135px;
    left: -15px;
  }

  .hsa-view {
    overflow-y: hidden;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .hsa-view-closed {
    overflow-y: hidden;
    width: 100%;
    height: calc(100vh - 110px);
    margin-left: 10px;
  }

  .no-padding {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }

  .counting-model-container  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 130px);
    
    .counting-model {
      width: 100%;
      margin-top: 3px;
      max-width: 760px;
      height: 100%;
      padding: 10px 20px;
      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      overflow: hidden;
      position: relative;
      overflow-y: auto;
    }
  }
  
}
