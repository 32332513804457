$heijmans-yellow: rgba(254, 184, 0, 1);
$heijmans-red: rgba(255, 20, 0, 1);
$heijmans-blue: rgba(0, 52, 102, 1);
$textColor: $heijmans-blue;
$input-label-color: #8a8989;
$textColorDisabled: rgba(0, 52, 102, 0.3);
$navLinkBackgroundColor: $heijmans-blue;
$navLinkTextColor: white;
$navLinkHoverImageColor: $heijmans-blue;
$navLinkSelectedTextColor: $heijmans-yellow;
$navLinkSelectedBackgroundColor: $heijmans-blue;
$disabledColor: #c6c6c6;
