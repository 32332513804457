.left-sidebar-view-layout {
  display: flex;
  position: relative;
  height: 100vh;
  overflow: hidden;

  .first-column {
    position: relative;
    min-width: 325px;
    max-width: 325px;
    display: inline-block;
    border-right: 1px solid #e0e0e0;
    height: 100%;
    overflow: hidden;
  }

  .second-column {
    width: 100%;
    padding: 20px;
    overflow: hidden;
    height: 100%;
  }

  .collapse-button {
    position: absolute;
    display: flex;
    left: 325px;
    top: 0;
  }

  .expand-button {
    position: absolute;
    display: flex;
    top: 5px;
    left: -15px;
  }

  .first-column-pane-closed {
    min-width: 0;
    height: 100%;
    min-height: 1080px;
    overflow: hidden;
  }

  .second-column-pane-closed {
    width: 100vw;
    padding: 20px;
    height: 100%;
    overflow: hidden;
  }

  .MuiCollapse-wrapperInner {
    width: 100% !important;
  }
}
