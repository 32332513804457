.unit-mix {
  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__count-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__count,
  &__percentage {
    font-size: 12px;

    &_project {
      display: flex;
      justify-content: center;

      &-red {
        margin-left: 3px;
        color: red;
      }
    }
  }

  &__percentage-input {
    display: flex;
    align-items: center;
  }

  &__name-container {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
  }

  &__color-indicator {
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }

  &__item-name {
    font-size: 14px;
  }

  &__slider-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .column-title {
    font-size: 10px;
    color: #acacac;
  }
}
