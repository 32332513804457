.categories-view {
  .categories-view-header {
    position: relative;

    .button-group {
      position: absolute;
      right: 5px;
      top: -10px;
    }
  }
  .categories-view-treeview {
    margin-top: 10px;
    max-height: calc(100vh - 145px);
    overflow-y: auto;
  }
}
