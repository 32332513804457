
.settings-page {
  padding: 0px 20px 0px 0px;

  .settings-page-content{
    display: grid;
    grid-template-columns: 225px 1fr;
  }

  .settings-page-content-general,
  .settings-page-content-rights {
    height: calc(100vh - 60px);
    padding-left: 20px;
    padding-right: 20px;
  }
}
