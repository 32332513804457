@import "../../../../styles/theme-variables.scss";

.navigation-sidebar {
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 25rem;
  max-width: 25rem;
  height: calc(100vh - var(--offset));
  display: flex;
  flex-direction: column;

  .navigation-sidebar-content {
    overflow-y: auto;
    padding: 20px 0px 20px 0px;
    flex: 1;
  }

  .navigation-sidebar-button {
    position: absolute;
    display: flex;
    left: 22rem;
  }

  .tree-view {
    margin-top: 10px;
    padding: 10px;
    flex: 1;
  }

  .navigation-sidebar-footer {
    margin-top: auto;
    padding: 0px 20px 0px 20px;

    :hover {
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  .navigation-sidebar-footer-line {
    background-color: "rgba(0, 0, 0, 0.12)";
  }

  .navigation-sidebar-footer-settings {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 5px;
    :hover {
      background-color: transparent;
    }
  }
}
