.basic-principles-control {
  .map-view-controls-control-view {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .empty-drawable-plot {
      padding: 0px;
      margin-top: 10px;
      display: flex;
      align-items: center;

      .empty-drawable-plot-label {
        flex: 1;
        color: #c6c6c6;
      }

      .empty-drawable-plot-popover {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .drawable-plot {
      display: flex;
      align-items: center;

      .drawable-plot-popover {
        display: flex;
        align-items: center;
      }

      .drawable-plot-circle {
        margin-right: 5px;
        width: 18px;
        height: 18px;
        background-color: rgba(0, 0, 0, 1);
        border: 1px solid #000;
        border-radius: 20px;
      }

      .drawable-plot-label-button-group-view {
        display: flex;
        align-items: center;
    

        .drawable-plot-label {
          flex: 1;
          text-align: end;
        }

        .drawable-plot-button-group {
          display: flex;
          justify-content: start;
          align-items: start;
        }
      }
    }

    .buildable-area {
      display: flex;
      align-items: start;

      .buildable-area-labels {
        margin-top: 3px;
      }

      .buildable-area-circle {
        margin-top: 3px;
        margin-right: 5px;
        width: 18px;
        height: 18px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid #000;
        border-radius: 20px;
      }
    }
  }

  .generator-inputs {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .input-plot-view {
    margin-left: -20px;
    display: flex;

    .input-plot-input {
      border: 0;
      outline: none;
      font-size: 95%;
      width: 35px;
      color: rgba(0, 0, 0, 0.87);
    }

    .input-plot-percentage {
      margin-left: -15px;
    }
  }

  .dinstance-between-plot-and-road-view {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;

    .distance-between-plot-and-road-circle {
      margin-right: 5px;
      width: 18px;
      height: 18px;
      background-color: #3887be;
      border: 1px solid #000;
      border-radius: 20px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
