@import "../../styles/colors.scss";
@import "../../styles/theme-variables.scss";

.base-database-page {
  position: relative;
  overflow-y: hidden;

  .button-group {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .base-database-page-view {
    padding: 20px;
  }

  .open-input-pane-button {
    position: absolute;
    display: flex;
    left: -15px;
  }

  .base-database-inputpane-view {
    display: grid;
    grid-template-columns: 0.5rem calc(100vw - 0.5rem);
    transition: width 0.2s ease-out;
  }

  .base-database-inputpane-view.side-bar-open {
    grid-template-columns: 25rem calc(100vw - 25rem);
    transition: width 0.2s ease-out;
    width: 100%;
  }

  .title {
    font-size: 14px;
  }

  .dialog-title {
    display: flex;
    align-items: center;
    font-size: 16;
    font-weight: 900;
  }
}
