.expandable-list-item-button {
  .main-button {
    position: relative;

    .expandable-button-icon {
      display: flex;
      justify-content: center;
    }
  }
}
